import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { datadogRum } from '@datadog/browser-rum';

// https://app.datadoghq.com/rum/performance-monitoring?query=%40application.id%3Ae1d40f96-5359-484a-af9e-9f4a02291147%20%40
export default defineNuxtPlugin(({ isDev, $config }) => {
    if (isDev || !process.client || $config.DEPLOY_ENV === 'dev') return;

    datadogRum.init({
        applicationId: 'e1d40f96-5359-484a-af9e-9f4a02291147',
        clientToken: 'pub2f716cf4c29006ab0ac0b92e658e29a9',
        site: 'datadoghq.com',
        service: 'marketplace-dashboard',
        env: $config.DEPLOY_ENV,
        version: $config.VERSION,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
});
